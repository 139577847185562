import React, { useState } from 'react'
import { DateRangePicker } from 'react-dates'

import styles from './date-picker.module.scss'
import CustomArrow from '../svg/CustomArrow.svg'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft,
  faCalendar,
} from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'next-i18next'
import classes from '../pages/destinations/destinations.module.scss'
import moment from 'moment'

const WTDatePicker = (props) => {
  const { onChange, defaultStart, defaultEnd, showIcon } = props
  const [dates, setDates] = useState({
    startDate: defaultStart,
    endDate: defaultEnd,
  })
  const [focus, setFocus] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const { t, i18n } = useTranslation()
  const hideLabels = props.hasOwnProperty('hideLabels')
  return (
    <React.Fragment>
      {!hideLabels && (
        <div className={styles.labels}>
          <div>
            <span>Check in:</span>
          </div>
          <div>
            <span>Check out:</span>
          </div>
        </div>
      )}
      <DateRangePicker
        startDateId="startDate"
        endDateId="endDate"
        startDate={dates.startDate}
        startDatePlaceholderText={t('add-date')}
        endDatePlaceholderText={t('add-date')}
        endDate={dates.endDate}
        onDatesChange={({ startDate, endDate }) => {
          let midnightStart = null,
            midnightEnd = null
          if (!startDate) {
            midnightStart = moment()
          } else if (moment.isMoment(startDate)) {
            midnightStart = startDate
          } else {
            midnightStart = moment(startDate)
          }
          if (!endDate) {
            midnightEnd = moment(startDate)
            midnightEnd.add(1, 'day')
          } else if (endDate && moment.isMoment(endDate)) {
            midnightEnd = endDate
          } else {
            midnightEnd = moment(startDate).add(1, 'day')
          }

          setDates({
            startDate: midnightStart,
            endDate: midnightEnd,
          })
          onChange({
            startDate: midnightStart,
            endDate: midnightEnd,
          })
        }}
        focusedInput={focus}
        onFocusChange={(focusedInput) => {
          setFocus(focusedInput)
        }}
        displayFormat={() => 'DD/MM/YYYY'}
        numberOfMonths={1}
        customArrowIcon={<span className={styles.customArrow} />}
        noBorder
        enableOutsideDays
        hideKeyboardShortcutsPanel
        navPrev={<FontAwesomeIcon icon={faChevronLeft} />}
        navNext={<FontAwesomeIcon icon={faChevronRight} />}
      />
      {showIcon && (
        <div
          className={classes.icon}
          onClick={() => {
            setFocus('startDate')
          }}
        >
          <FontAwesomeIcon icon={faCalendar} />
        </div>
      )}
    </React.Fragment>
  )
}
WTDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  hideLabels: PropTypes.bool,
  defaultStart: PropTypes.object || PropTypes.string,
  defaultEnd: PropTypes.object || PropTypes.string,
  showIcon: PropTypes.bool,
}
export default WTDatePicker
