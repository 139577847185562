import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './widgets/header-search-bar.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons'
import PlusCircle from './svg/PlusCircle.svg'

const Occupants = ({ room, index, onChange }) => {
  const [adults, setAdults] = useState([])
  const [numAdults, setNumAdults] = useState(0)
  const [children, setChildren] = useState([])
  const [numChildren, setNumChildren] = useState(0)
  const [reRender, setReRender] = useState(false)
  const handleAdults = (num) => {
    const newAdults = adults
    if (num > newAdults.length) {
      newAdults.push({
        age: 30,
      })
    } else {
      newAdults.pop()
    }
    setNumAdults(num)
    setAdults(newAdults)
    room.paxes = [...newAdults, ...children]
    onChange(index, room)
  }
  const handleChildren = (num) => {
    const newChildren = children
    if (num > newChildren.length) {
      newChildren.push({
        age: 12,
      })
    } else {
      newChildren.pop()
    }
    setChildren(newChildren)
    setNumChildren(newChildren.length)
    room.paxes = [...adults, ...newChildren]
    onChange(index, room)
  }
  const handleAge = (idx, age) => {
    const currentChildren = children
    currentChildren[idx].age = age
    setChildren(currentChildren)
    setReRender(!reRender)
    room.paxes = [...adults, ...currentChildren]
    onChange(index, room)
  }
  useEffect(() => {
    let a = [],
      c = []
    room.paxes.forEach(({ age }, i) => {
      if (age >= 18) {
        a.push({
          age,
        })
      } else {
        c.push({
          age,
        })
      }
    })
    setAdults(a)
    setNumAdults(a.length)
    setChildren(c)
    setNumChildren(c.length)
  }, [])
  return (
    <>
      <ul>
        <li>
          <h4>
            Adults:<span>Age 18+</span>
          </h4>
          <div>
            <span
              className={styles.addGuests}
              onClick={() => {
                if (adults.length > 0) {
                  handleAdults(adults.length - 1)
                }
              }}
            >
              <FontAwesomeIcon icon={faMinusCircle} />
            </span>
            <h5>{numAdults}</h5>
            <span
              className={styles.addGuests}
              onClick={() => {
                handleAdults(adults.length + 1)
              }}
            >
              <PlusCircle />
            </span>
          </div>
        </li>
        <li>
          <h4>
            Children:<span>Age up to 18</span>
          </h4>
          <div>
            <span
              className={styles.addGuests}
              onClick={() => {
                if (children.length > 0) {
                  handleChildren(children.length - 1)
                }
              }}
            >
              <FontAwesomeIcon icon={faMinusCircle} />
            </span>
            <h5>{numChildren}</h5>
            <span
              className={styles.addGuests}
              onClick={() => {
                handleChildren(children.length + 1)
              }}
            >
              <PlusCircle />
            </span>
          </div>
        </li>
      </ul>
      {children.length > 0 && (
        <>
          <h6>Age of children upon return:</h6>
          <ul>
            {children.map((child, i) => {
              return (
                <li key={i}>
                  <h4>Child {i + 1}</h4>
                  <div>
                    <span
                      className={styles.addGuests}
                      onClick={() => {
                        if (child.age > 0) {
                          handleAge(i, child.age - 1)
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </span>
                    <h5>{child.age}</h5>
                    <span
                      className={styles.addGuests}
                      onClick={() => {
                        if (child.age < 17) {
                          handleAge(i, child.age + 1)
                        }
                      }}
                    >
                      <PlusCircle />
                    </span>
                  </div>
                </li>
              )
            })}
          </ul>
        </>
      )}
    </>
  )
}
Occupants.propTypes = {
  room: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}
export default Occupants
