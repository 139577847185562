import styles from '../widgets/header-search-bar.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons'
import PlusCircle from '../svg/PlusCircle.svg'
import Popup from '../popup'
import React, { useState } from 'react'
import Occupants from '../occupants'
import Button from '../button'

const GuestsPopup = ({ popupState, guests, handleChange, top = 100 }) => {
  const [rooms, setRooms] = useState(guests)
  const [reRender, setReRender] = useState(false)

  const addGuest = (index, item) => {
    const newRooms = rooms
    newRooms[index] = item
    setRooms(newRooms)
    handleChange(newRooms)
  }
  const addRoom = () => {
    addGuest(rooms.length, {
      paxes: [{ age: 30 }],
    })
  }
  const removeRoom = () => {
    const newRooms = rooms
    newRooms.pop()
    setRooms(newRooms)
    handleChange(newRooms)
  }
  return (
    <Popup
      onClose={() => popupState(false)}
      style={{
        left: '50%',
        transform: 'translateX(-50%)',
        width: '200%',
        top: `${top}%`,
      }}
      position={'center'}
    >
      <ul>
        <li className={`guests-option ${styles.guestsPopupOption}`}>
          {rooms.map((room, i) => {
            return (
              <div className={styles.optionSelect} key={i}>
                <h4>
                  Room {i + 1}:
                  {i === rooms.length - 1 && rooms.length !== 1 && (
                    <span
                      className={`${styles.removeRoom}`}
                      onClick={removeRoom}
                    />
                  )}
                </h4>
                <Occupants room={room} index={i} onChange={addGuest} />
              </div>
            )
          })}
          <div className={`add-room ${styles.addRoom}`}>
            <div onClick={addRoom}>
              <PlusCircle />
            </div>
            <p>Add Room</p>
          </div>
        </li>
        <li
          className={`guests-option ${styles.guestsPopupOption} ${styles.rightAligned}`}
        >
          <Button width={'100%'} onClick={() => popupState(false)}>
            Done
          </Button>
        </li>
      </ul>
    </Popup>
  )
}
export default GuestsPopup
