import styles from './wrapper.module.scss'
import PropTypes from 'prop-types'
const Wrapper = ({
  background,
  children,
  margin,
  overflowHidden,
  withPadding,
  withBottomPadding,
  inlineTitle,
  noMargin,
  id,
  smallMargin,
  flexGrow,
  negMargin
}) => {
  const wrapperProps = {
    id,
  }
  return (
    <div
      {...wrapperProps}
      className={`${styles.wrapper} ${
        styles.hasOwnProperty(background) ? styles[background] : styles.white
      } ${margin ? styles.margined : ''} ${
        inlineTitle ? styles.inlineTitle : ''
      } ${overflowHidden ? styles.overflowHidden : ''} ${
        withPadding === true ? styles.withPadding : ''
      } ${withBottomPadding === true ? styles.withBottomPadding : ''} ${
        noMargin ? styles.noMargin : ''
      } ${flexGrow ? styles.flexGrow : ''} ${
        smallMargin === true ? styles.smallMargin : ''
      } ${negMargin ? styles.negBottomMargin : ''}`}
    >
      {children}
    </div>
  )
}
Wrapper.propTypes = {
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
  margin: PropTypes.bool,
  overflowHidden: PropTypes.bool,
  withPadding: PropTypes.bool,
  withBottomPadding: PropTypes.bool,
  inlineTitle: PropTypes.bool,
  noMargin: PropTypes.bool,
  id: PropTypes.string,
  smallMargin: PropTypes.bool,
  flexGrow: PropTypes.bool,
  negMargin: PropTypes.bool
}
export default Wrapper
